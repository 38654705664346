<template>
  <div>
    <v-row class="ma-0 mb-1" justify="space-between">
      <v-col cols="4">
        <v-text-field
          :background-color="search ? 'serenade' : ''"
          v-model.trim="search"
          prepend-inner-icon="mdi-magnify"
          outlined
          hide-details
          dense
          clearable
          :placeholder="'search'|localize"
        />
      </v-col>
      <v-col cols="auto" class="d-flex">
        <v-btn class="mr-2" text color="primary" @click="resetFilters();clearMargins();getMargins()"><v-icon left>mdi-sync</v-icon> {{ 'resetFilters'|localize }}</v-btn>
        <v-btn :to="{name: 'create-third-level'}" color="primary" depressed rounded>
          <v-icon size="25" left class="mr-3">mdi-plus</v-icon>
          {{ 'createMargin'|localize }}
        </v-btn>
      </v-col>
    </v-row>
    <v-container class="py-0">
      <v-row justify="end">
        <v-col cols="3">
          <v-select
            :background-color="filters.customer.length ? 'serenade' : ''"
            v-model="filters.customer"
            :items="customers"
            :disabled="filtersLoading"
            dense
            hide-details
            outlined
            clearable
            multiple
            :placeholder="'customer'|localize"
            @change="clearMargins();getMargins();"
          >
            <template v-slot:item="{ item, on }">
              <v-list-item-title>{{ item }}</v-list-item-title>
            </template>
            <template v-slot:selection="{ item, index }">
              <div class="d-flex">
                <span v-if="index === 0" class="text-truncate" style="max-width: 100px">{{ item }}</span>
                <span v-if="index === 1" class="grey--text text-caption">+{{ filters.customer.length - 1 }}</span>
              </div>
            </template>
          </v-select>
        </v-col>
        <v-col cols="2">
          <v-select
            :background-color="filters.transport_mode.length ? 'serenade' : ''"
            v-model="filters.transport_mode"
            :items="transportModeList"
            :disabled="filtersLoading"
            item-text="name"
            item-value="value"
            dense
            hide-details
            outlined
            clearable
            multiple
            :placeholder="'transportMode'|localize"
            @change="clearMargins();getMargins();"
          >
            <template v-slot:item="{ item, on }">
              <v-icon class="mr-2" color="silver-chalice">{{ TRANSPORT_ICONS[item['value']] }}</v-icon>
              <v-list-item-title>{{ item.value|localize }}</v-list-item-title>
            </template>
            <template v-slot:selection="{ item, index }">
              <v-icon v-if="index <= 1" class="mr-1" color="silver-chalice">{{ TRANSPORT_ICONS[item['value']] }}</v-icon>
              <span v-if="index === 2" class="grey--text text-caption">+{{ filters.transport_mode.length - 2 }}</span>
            </template>
          </v-select>
        </v-col>
        <v-col cols="2">
          <v-select
            :background-color="filters.from.length ? 'serenade' : ''"
            v-model="filters.from"
            :items="countriesFrom"
            :disabled="filtersLoading"
            dense
            hide-details
            outlined
            :placeholder="'from'|localize"
            clearable
            multiple
            @change="clearMargins();getMargins();"
          >
            <template v-slot:item="{ item, on }">
              <v-list-item-title>{{ item }}</v-list-item-title>
            </template>
            <template v-slot:selection="{ item, index }">
              <div class="d-flex">
                <span v-if="index === 0" class="text-truncate" style="max-width: 50px">{{ item }}</span>
                <span v-if="index === 1" class="grey--text text-caption">+{{ filters.from.length - 1 }}</span>
              </div>
            </template>
          </v-select>
        </v-col>
        <v-col cols="2">
          <v-select
            :background-color="filters.to.length ? 'serenade' : ''"
            v-model="filters.to"
            :items="countriesTo"
            :disabled="filtersLoading"
            dense
            hide-details
            outlined
            :placeholder="'to'|localize"
            clearable
            multiple
            @change="clearMargins();getMargins();"
          >
            <template v-slot:item="{ item, on }">
              <v-list-item-title>{{ item }}</v-list-item-title>
            </template>
            <template v-slot:selection="{ item, index }">
              <div class="d-flex">
                <span v-if="index === 0" class="text-truncate" style="max-width: 50px">{{ item }}</span>
                <span v-if="index === 1" class="grey--text text-caption">+{{ filters.to.length - 1 }}</span>
              </div>
            </template>
          </v-select>
        </v-col>
        <v-col cols="3">
          <v-select
            :background-color="filters.company.length ? 'serenade' : ''"
            v-model="filters.company"
            :items="companies"
            :disabled="filtersLoading"
            dense
            hide-details
            outlined
            clearable
            multiple
            :placeholder="'company'|localize"
            @change="clearMargins();getMargins();"
          >
            <template v-slot:item="{ item, on }">
              <v-list-item-title>{{ item }}</v-list-item-title>
            </template>
            <template v-slot:selection="{ item, index }">
              <div class="d-flex">
                <span v-if="index === 0" class="text-truncate" style="max-width: 100px">{{ item }}</span>
                <span v-if="index === 1" class="grey--text text-caption">+{{ filters.company.length - 1 }}</span>
              </div>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :headers="headers"
      :items="margins"
      :loading="loading"
      item-key="id + transport_mode"
      loader-height="2"
      :sort-by="defaultSortBy"
      :sort-desc="defaultSortDesc"
      :options.sync="options"
      :server-items-length="totalItems"
      hide-default-footer
      :no-data-text="'noDataAvailable'|localize"
    >
      <template v-slot:item.transport_mode="{ item }">
        <v-tooltip v-if="item.transport_mode" top color="black">
          <template v-slot:activator="{ on, attrs }">
            <v-icon class="ml-7" v-bind="attrs" v-on="on" color="silver-chalice">
              {{ TRANSPORT_ICONS[item['transport_mode']] }}
            </v-icon>
          </template>
          <span>{{ item['transport_mode']|localize }}</span>
        </v-tooltip>
        <div v-else>
          <v-tooltip v-for="mode in transportModeList" :key="mode.value" top color="black">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" color="silver-chalice">{{ TRANSPORT_ICONS[mode.value] }}</v-icon>
            </template>
            <span>{{ mode.value|localize }}</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:item.from="{ item }">
        <div v-if="item.from">{{ item.from }}</div>
        <div v-else>{{ 'anyCountry'|localize }}</div>
      </template>
      <template v-slot:item.to="{ item }">
        <div v-if="item.to">{{ item.to }}</div>
        <div v-else>{{ 'anyCountry'|localize }}</div>
      </template>
      <template v-slot:item.transport_company="{ item }">
        <div v-if="item.transport_company">{{ item.transport_company }}</div>
        <div v-else>{{ 'anyCompany'|localize }}</div>
      </template>
        <template v-slot:item.updated_at="{ item }">
          <div v-html="$options.filters.formatDate(item['updated_at'])"/>
        </template>
      <template v-slot:item.settings="{ item }">
        <v-progress-circular
          v-if="deleteQueue.includes(item.id)"
          indeterminate
          :width="1"
          :size="20"
        />
        <v-menu v-else left transition="slide-y-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-avatar v-bind="attrs" v-on="on" size="45" tile>
              <v-icon size="20">mdi-dots-vertical</v-icon>
            </v-avatar>
          </template>
          <v-list>
            <v-list-item link :to="{name: 'create-third-level', query: {margin_id: item.id}}">
              <v-list-item-title>{{ 'edit'|localize }}</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="openDelete(item)">
              <v-list-item-title>{{ 'delete'|localize }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-data-table>
    <v-pagination
      color="primary"
      v-model="currentPage"
      :length="totalPages"
      :total-visible="7"
      class="py-6"
      :disabled="loading"
      @input="margins = [];getMargins();"
    />
    <SimpleDialog
      ref="deleteDialog"
      :title="'deleteMargin'|localize"
      :text="'deleteMarginText'|localize"
      :accept-text="'delete'|localize"
      :decline-text="'back'|localize"
      @accept="deleteMargin"
      @decline="cancelDelete"
    />
  </div>
</template>

<script>
  import api from '@/api/api';
  import localize from '@/filters/localize';
  import {TRANSPORT_ICONS} from '@/enums/transportMode';
  import methods from '@/helpers/methods';
  import SimpleDialog from '@/components/common/SimpleDialog';

  export default {
    name: "ThirdLevel",
    components: {SimpleDialog},
    props: ['loading'],

    data() {
      return {
        TRANSPORT_ICONS,
        debouncedSearch: '',
        debounceTimeout: null,
        filtersLoading: false,
        defaultSortBy: 'updated_at',
        defaultSortDesc: true,
        options: {},
        itemsPerPage: 20,
        currentPage: 1,
        totalPages: 0,
        totalItems: 0,
        margins: [],
        companies: [],
        customers: [],
        countriesFrom: [],
        countriesTo: [],
        filters: {
          from: [],
          to: [],
          transport_mode: [],
          company: [],
          customer: []
        },
        deleteQueue: [],
        deleteMarginId: null,
      };
    },

    async mounted() {
      try {
        this.filtersLoading = true;
        let filtersData = await api.getThirdLevelFilters();
        this.companies = filtersData.data['company'];
        this.customers = filtersData.data['customer'];
        this.countriesFrom = filtersData.data['from'];
        this.countriesTo = filtersData.data['to'];
        this.filtersLoading = false;
      } catch (e) {
        this.filtersLoading = false;
      }
    },

    watch: {
      options: {
        handler() {
          this.clearMargins();
          this.getMargins();
        },
        deep: true,
      },
      search() {
        this.clearMargins();
        this.getMargins();
      },
    },

    computed: {
      headers() {
        return [
          {text: localize('customer'), value: 'customer', width: 125},
          {text: localize('customerId'), value: 'customer_id', width: 100},
          {text: localize('transportMode'), value: 'transport_mode', sortable: false, width: 110},
          {text: localize('from'), value: 'from', width: 100},
          {text: localize('to'), value: 'to', width: 100},
          {text: localize('transportCompany'), value: 'transport_company', width: 140},
          {text: `${localize('margins')} %`, value: 'margin', width: 100},
          {text: localize('updatedOn'), value: 'updated_at', width: 140},
          {text: '', value: 'settings', align: 'center', sortable: false, width: 50},
        ];
      },
      transportModeList() {
        return this.$store.getters.transportModeList;
      },
      search: {
        get() {
          return this.debouncedSearch
        },
        set(val) {
          if (this.debounceTimeout) clearTimeout(this.debounceTimeout)
          this.debounceTimeout = setTimeout(() => {
            this.debouncedSearch = val
          }, 500)
        }
      },
    },

    methods: {
      resetFilters() {
        this.filters.from = [];
        this.filters.to = [];
        this.filters.transport_mode = [];
        this.filters.company = [];
        this.filters.customer = [];
      },
      clearMargins() {
        this.margins = [];
        this.totalPages = 0;
        this.totalItems = 0;
        this.currentPage = 1;
      },
      openDelete(item) {
        this.$refs.deleteDialog.open();
        this.deleteMarginId = item.id;
      },
      cancelDelete() {
        this.deleteMarginId = null;
      },
      async deleteMargin() {
        let marginId = this.deleteMarginId;
        this.deleteMarginId = null;
        this.deleteQueue.push(marginId)
        try {
          await api.deleteThirdLevelMargin(marginId);
          let requestsData = await api.getThirdLevelMargins(this.queryData());
          this.setMarginsData(requestsData);
          this.deleteQueue = this.deleteQueue.filter(queueId => queueId !== marginId)
        } catch (e) {}
      },
      setMarginsData(requestsData) {
        this.margins = requestsData.data.data;
        this.totalPages = requestsData.data.pagination['total_page'];
        this.totalItems = requestsData.data.pagination['total_items'];
      },
      queryData() {
        let queryData = {};
        queryData['per_page'] = this.itemsPerPage;
        queryData['page'] = this.currentPage;
        this.options.sortBy[0] && (
          queryData['sort'] = {
            field: this.options.sortBy[0],
            desc: this.options.sortDesc[0],
          });
        this.search && (queryData['search'] = this.search);
        queryData.filters = {};
        this.filters.company.length && (queryData.filters.company = this.filters.company);
        this.filters.customer.length && (queryData.filters.customer = this.filters.customer);
        this.filters.transport_mode.length && (queryData.filters.transport_mode = this.filters.transport_mode);
        this.filters.from.length && (queryData.filters.from = this.filters.from);
        this.filters.to.length && (queryData.filters.to = this.filters.to);
        return queryData;
      },
      async getMargins() {
        try {
          this.$emit('updateLoading', true);
          let marginsData = await api.getThirdLevelMargins(this.queryData());
          this.setMarginsData(marginsData);
          this.$emit('updateLoading', false);
        } catch (e) {
          this.$emit('updateLoading', false);
        }
      }
    },
  };
</script>
