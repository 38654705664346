<template>
  <v-container class="pt-7 pb-11">
    <h1 class="text-h4 mb-5">{{ 'marginSettings'|localize }}</h1>
    <div class="white elevation-2 rounded">
      <v-row class="my-0" align="center">
        <v-col class="py-0">
          <v-tabs v-model="tab" background-color="transparent" class="grey--text text--lighten-1">
            <v-tab :ripple="false" href="#level-3" :disabled="loading" class="text-none">
              {{ 'level_3'|localize }}
            </v-tab>
            <v-tab :ripple="false" href="#level-2" :disabled="loading" class="text-none">
              {{ 'level_2'|localize }}
            </v-tab>
            <v-tab :ripple="false" href="#level-1" :disabled="loading" class="text-none">
              {{ 'level_1'|localize }}
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-divider class="mb-1"/>
      <template v-if="tab === 'level-3'">
        <ThirdLevel :loading="loading" @updateLoading="loading = $event"/>
      </template>
      <template v-if="tab === 'level-2'">
        <SecondLevel :loading="loading" @updateLoading="loading = $event"/>
      </template>
      <template v-if="tab === 'level-1'">
        <FirstLevel :loading="loading" @updateLoading="loading = $event"/>
      </template>
    </div>
  </v-container>
</template>

<script>
  import ThirdLevel from '@/components/ThirdLevel';
  import SecondLevel from '@/components/SecondLevel';
  import FirstLevel from '@/components/FirstLevel';

  export default {
    name: "PriceSettings",
    components: {FirstLevel, SecondLevel, ThirdLevel},

    data() {
      return {
        tab: 'level-3',
        loading: false
      };
    },
  };
</script>
