<template>
  <div>
    <v-form ref="marginForm" lazy-validation>
      <v-data-table
        :headers="headers"
        :items="margins"
        :loading="loading"
        loader-height="2"
        :items-per-page="-1"
        hide-default-footer
        :no-data-text="'noDataAvailable'|localize"
      >
        <template v-slot:item.category="{ item }">
          <v-text-field
            background-color="white"
            v-model="item.category"
            dense
            hide-details
            outlined
            maxlength="3"
            :rules="[existingCategoryRule(), validationRules.required]"
          />
        </template>
        <template v-slot:item.air="{ item }">
          <v-text-field
            :background-color="item.air || (item.air === 0) ? 'serenade' : 'white'"
            v-model.trim.number="item.air"
            @blur="item.air = item.air !== '' ? item.air : null"
            dense
            hide-details
            outlined
            :rules="[validationRules.integer]"
          />
        </template>
        <template v-slot:item.sea="{ item }">
          <v-text-field
            :background-color="item.sea || (item.sea === 0) ? 'serenade' : 'white'"
            v-model.trim.number="item.sea"
            @blur="item.sea = item.sea !== '' ? item.sea : null"
            dense
            hide-details
            outlined
            :rules="[validationRules.integer]"
          />
        </template>
        <template v-slot:item.rail="{ item }">
          <v-text-field
            :background-color="item.rail || (item.rail === 0) ? 'serenade' : 'white'"
            v-model.trim.number="item.rail"
            @blur="item.rail = item.rail !== '' ? item.rail : null"
            dense
            hide-details
            outlined
            :rules="[validationRules.integer]"
          />
        </template>
        <template v-slot:item.road="{ item }">
          <v-text-field
            :background-color="item.road || (item.road === 0)? 'serenade' : 'white'"
            v-model.trim.number="item.road"
            @blur="item.road = item.road !== '' ? item.road : null"
            dense
            hide-details
            outlined
            :rules="[validationRules.integer]"
          />
        </template>
        <template v-slot:item.settings="{ item }">
          <v-btn
            v-if="item.category && item.category !== null"
            fab
            depressed
            x-small
            color="primary"
            :disabled="addLoading || saveLoading || loading"
            @click="openRemoveCategory(item.category)"
          > 
            <v-icon size="25">mdi-minus</v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-form>
    <v-container>
      <v-row>
        <v-col cols="6" class="d-flex">
          <v-btn
            rounded
            depressed
            color="primary"
            :loading="addLoading"
            :disabled="addLoading || saveLoading || loading"
            @click="addCategory"
          > 
            <v-icon size="25" left class="mr-3">mdi-plus</v-icon>
            {{ 'addCategory'|localize }}
          </v-btn>
        </v-col>
        <v-col cols="6" class="d-flex justify-end">
          <v-btn
            rounded
            depressed
            color="primary"
            :loading="saveLoading"
            :disabled="addLoading || saveLoading || loading"
            @click="saveMargin"
          > {{ 'save'|localize }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <SimpleDialog
      ref="deleteDialog"
      :title="'deleteCategoryTitle'|localize"
      :text="'deleteCategoryText'|localize"
      :accept-text="'delete'|localize"
      :decline-text="'back'|localize"
      @accept="removeCategory"
      @decline="cancelRemoveCategory"
    />
  </div>
</template>

<script>
  import validationRules from '../helpers/validationRules';
  import localize from '@/filters/localize';
  import api from '@/api/api';
  import methods from '@/helpers/methods';
  import SimpleDialog from '@/components/common/SimpleDialog';

  export default {
    name: "SecondLevel",
    props: ['loading'],
    components: {SimpleDialog},

    data() {
      return {
        validationRules: validationRules,
        margins: [],
        saveLoading: false,
        addLoading: false,
        removeCategoryId: null,
      };
    },

    mounted() {
      this.getMargins();
    },

    computed: {
      headers() {
        return [
          {text: localize('category'), value: 'category', width: 100, sortable: false},
          {text: localize('air') + ', %', value: 'air', sortable: false},
          {text: localize('sea') + ', %', value: 'sea', sortable: false},
          {text: localize('rail') + ', %', value: 'rail', sortable: false},
          {text: localize('road') + ', %', value: 'road', sortable: false},
          {text: '', value: 'settings', align: 'center', sortable: false, width: 50},
        ];
      },
    },

    methods: {
      setMarginsData(requestsData) {
        this.margins = requestsData.data;
        this.margins.sort((a,b) => (a.category > b.category) ? 1 : ((b.category > a.category) ? -1 : 0))
      },
      async getMargins() {
        try {
          this.$emit('updateLoading', true);
          let marginsData = await api.getSecondLevelMargins();
          this.setMarginsData(marginsData);
          this.$emit('updateLoading', false);
        } catch (e) {
          this.$emit('updateLoading', false);
        }
      },
      queryData() {
        let queryData = {};
        let item = {};
        this.margins.forEach(element => {
          item = {
            "air": element.air,
            "sea": element.sea,
            "rail": element.rail,
            "road": element.road
          };
          element.category = element.category.toUpperCase();
          queryData[element.category] = item
        })
        return {'margin': JSON.stringify(queryData)};
      },
      async saveMargin() {
        if (this.$refs.marginForm.validate()) {
          try {
            this.saveLoading = true;
            await api.saveSecondLevelMargin(this.queryData());
            let marginsData = await api.getSecondLevelMargins();
            this.setMarginsData(marginsData);
            this.saveLoading = false;
            methods.showGlobalSnackbar('success', localize('done'));
          } catch (e) {
            this.saveLoading = false;
            methods.showGlobalSnackbar('error', methods.getErrors(e.response));
          }
        }
      },
      addCategory() {
        this.addLoading = true;
        let item = {
          "category": null,
          "air": null,
          "sea": null,
          "rail": null,
          "road": null,
        };
        this.margins.push(item);
        this.addLoading = false;
      },
      openRemoveCategory(category) {
        this.$refs.deleteDialog.open();
        this.removeCategoryId = this.margins.findIndex(object => object.category === category);
      },
      cancelRemoveCategory() {
        this.removeCategoryId = null;
      },
      removeCategory() {
        this.margins.splice(this.removeCategoryId, 1);
        this.saveMargin();
      },
      existingCategoryRule() {
        return v => !v || this.margins.filter(x => x.category) === null || this.margins.filter(x => x.category.toUpperCase() === v.toUpperCase()).length <= 1 || '';
      }
    },
  };
</script>
