var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"ma-0 mb-1",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"background-color":_vm.search ? 'serenade' : '',"prepend-inner-icon":"mdi-magnify","outlined":"","hide-details":"","dense":"","clearable":"","placeholder":_vm._f("localize")('search')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"mr-2",attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.resetFilters();_vm.clearMargins();_vm.getMargins()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-sync")]),_vm._v(" "+_vm._s(_vm._f("localize")('resetFilters')))],1),_c('v-btn',{attrs:{"to":{name: 'create-third-level'},"color":"primary","depressed":"","rounded":""}},[_c('v-icon',{staticClass:"mr-3",attrs:{"size":"25","left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm._f("localize")('createMargin'))+" ")],1)],1)],1),_c('v-container',{staticClass:"py-0"},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"background-color":_vm.filters.customer.length ? 'serenade' : '',"items":_vm.customers,"disabled":_vm.filtersLoading,"dense":"","hide-details":"","outlined":"","clearable":"","multiple":"","placeholder":_vm._f("localize")('customer')},on:{"change":function($event){_vm.clearMargins();_vm.getMargins();}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
return [_c('v-list-item-title',[_vm._v(_vm._s(item))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex"},[(index === 0)?_c('span',{staticClass:"text-truncate",staticStyle:{"max-width":"100px"}},[_vm._v(_vm._s(item))]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v("+"+_vm._s(_vm.filters.customer.length - 1))]):_vm._e()])]}}]),model:{value:(_vm.filters.customer),callback:function ($$v) {_vm.$set(_vm.filters, "customer", $$v)},expression:"filters.customer"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"background-color":_vm.filters.transport_mode.length ? 'serenade' : '',"items":_vm.transportModeList,"disabled":_vm.filtersLoading,"item-text":"name","item-value":"value","dense":"","hide-details":"","outlined":"","clearable":"","multiple":"","placeholder":_vm._f("localize")('transportMode')},on:{"change":function($event){_vm.clearMargins();_vm.getMargins();}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"color":"silver-chalice"}},[_vm._v(_vm._s(_vm.TRANSPORT_ICONS[item['value']]))]),_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("localize")(item.value)))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index <= 1)?_c('v-icon',{staticClass:"mr-1",attrs:{"color":"silver-chalice"}},[_vm._v(_vm._s(_vm.TRANSPORT_ICONS[item['value']]))]):_vm._e(),(index === 2)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v("+"+_vm._s(_vm.filters.transport_mode.length - 2))]):_vm._e()]}}]),model:{value:(_vm.filters.transport_mode),callback:function ($$v) {_vm.$set(_vm.filters, "transport_mode", $$v)},expression:"filters.transport_mode"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"background-color":_vm.filters.from.length ? 'serenade' : '',"items":_vm.countriesFrom,"disabled":_vm.filtersLoading,"dense":"","hide-details":"","outlined":"","placeholder":_vm._f("localize")('from'),"clearable":"","multiple":""},on:{"change":function($event){_vm.clearMargins();_vm.getMargins();}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
return [_c('v-list-item-title',[_vm._v(_vm._s(item))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex"},[(index === 0)?_c('span',{staticClass:"text-truncate",staticStyle:{"max-width":"50px"}},[_vm._v(_vm._s(item))]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v("+"+_vm._s(_vm.filters.from.length - 1))]):_vm._e()])]}}]),model:{value:(_vm.filters.from),callback:function ($$v) {_vm.$set(_vm.filters, "from", $$v)},expression:"filters.from"}})],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-select',{attrs:{"background-color":_vm.filters.to.length ? 'serenade' : '',"items":_vm.countriesTo,"disabled":_vm.filtersLoading,"dense":"","hide-details":"","outlined":"","placeholder":_vm._f("localize")('to'),"clearable":"","multiple":""},on:{"change":function($event){_vm.clearMargins();_vm.getMargins();}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
return [_c('v-list-item-title',[_vm._v(_vm._s(item))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex"},[(index === 0)?_c('span',{staticClass:"text-truncate",staticStyle:{"max-width":"50px"}},[_vm._v(_vm._s(item))]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v("+"+_vm._s(_vm.filters.to.length - 1))]):_vm._e()])]}}]),model:{value:(_vm.filters.to),callback:function ($$v) {_vm.$set(_vm.filters, "to", $$v)},expression:"filters.to"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"background-color":_vm.filters.company.length ? 'serenade' : '',"items":_vm.companies,"disabled":_vm.filtersLoading,"dense":"","hide-details":"","outlined":"","clearable":"","multiple":"","placeholder":_vm._f("localize")('company')},on:{"change":function($event){_vm.clearMargins();_vm.getMargins();}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
return [_c('v-list-item-title',[_vm._v(_vm._s(item))])]}},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex"},[(index === 0)?_c('span',{staticClass:"text-truncate",staticStyle:{"max-width":"100px"}},[_vm._v(_vm._s(item))]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v("+"+_vm._s(_vm.filters.company.length - 1))]):_vm._e()])]}}]),model:{value:(_vm.filters.company),callback:function ($$v) {_vm.$set(_vm.filters, "company", $$v)},expression:"filters.company"}})],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.margins,"loading":_vm.loading,"item-key":"id + transport_mode","loader-height":"2","sort-by":_vm.defaultSortBy,"sort-desc":_vm.defaultSortDesc,"options":_vm.options,"server-items-length":_vm.totalItems,"hide-default-footer":"","no-data-text":_vm._f("localize")('noDataAvailable')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.transport_mode",fn:function(ref){
var item = ref.item;
return [(item.transport_mode)?_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"ml-7",attrs:{"color":"silver-chalice"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.TRANSPORT_ICONS[item['transport_mode']])+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("localize")(item['transport_mode'])))])]):_c('div',_vm._l((_vm.transportModeList),function(mode){return _c('v-tooltip',{key:mode.value,attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"silver-chalice"}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.TRANSPORT_ICONS[mode.value]))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm._f("localize")(mode.value)))])])}),1)]}},{key:"item.from",fn:function(ref){
var item = ref.item;
return [(item.from)?_c('div',[_vm._v(_vm._s(item.from))]):_c('div',[_vm._v(_vm._s(_vm._f("localize")('anyCountry')))])]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [(item.to)?_c('div',[_vm._v(_vm._s(item.to))]):_c('div',[_vm._v(_vm._s(_vm._f("localize")('anyCountry')))])]}},{key:"item.transport_company",fn:function(ref){
var item = ref.item;
return [(item.transport_company)?_c('div',[_vm._v(_vm._s(item.transport_company))]):_c('div',[_vm._v(_vm._s(_vm._f("localize")('anyCompany')))])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.formatDate(item['updated_at']))}})]}},{key:"item.settings",fn:function(ref){
var item = ref.item;
return [(_vm.deleteQueue.includes(item.id))?_c('v-progress-circular',{attrs:{"indeterminate":"","width":1,"size":20}}):_c('v-menu',{attrs:{"left":"","transition":"slide-y-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({attrs:{"size":"45","tile":""}},'v-avatar',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":"","to":{name: 'create-third-level', query: {margin_id: item.id}}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("localize")('edit')))])],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.openDelete(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm._f("localize")('delete')))])],1)],1)],1)]}}])}),_c('v-pagination',{staticClass:"py-6",attrs:{"color":"primary","length":_vm.totalPages,"total-visible":7,"disabled":_vm.loading},on:{"input":function($event){_vm.margins = [];_vm.getMargins();}},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}),_c('SimpleDialog',{ref:"deleteDialog",attrs:{"title":_vm._f("localize")('deleteMargin'),"text":_vm._f("localize")('deleteMarginText'),"accept-text":_vm._f("localize")('delete'),"decline-text":_vm._f("localize")('back')},on:{"accept":_vm.deleteMargin,"decline":_vm.cancelDelete}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }