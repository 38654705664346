<template>
  <div>
    <v-form ref="marginForm" lazy-validation>
      <v-data-table
        :headers="headers"
        :items="margins"
        :loading="loading"
        item-key="category"
        loader-height="2"
        :items-per-page="-1"
        hide-default-footer
        :no-data-text="'noDataAvailable'|localize"
      >
        <template v-slot:item.air="{ item }">
          <v-text-field
            :background-color="item.air || (item.air === 0) ? 'serenade' : 'white'"
            v-model.trim.number="item.air"
            outlined
            hide-details
            dense
            :placeholder="'air'|localize"
            :rules="[validationRules.integer]"
            @blur="!item.air && (item.air = 0)"
          />
        </template>
        <template v-slot:item.sea="{ item }">
          <v-text-field
            :background-color="item.sea || (item.sea === 0) ? 'serenade' : 'white'"
            v-model.trim.number="item.sea"
            outlined
            hide-details
            dense
            :placeholder="'sea'|localize"
            :rules="[validationRules.integer]"
            @blur="!item.sea && (item.sea = 0)"
          />
        </template>
        <template v-slot:item.rail="{ item }">
          <v-text-field
            :background-color="item.rail || (item.rail === 0) ? 'serenade' : 'white'"
            v-model.trim.number="item.rail"
            outlined
            hide-details
            dense
            :placeholder="'rail'|localize"
            :rules="[validationRules.integer]"
            @blur="!item.rail && (item.rail = 0)"
          />
        </template>
        <template v-slot:item.road="{ item }">
          <v-text-field
            :background-color="item.road || (item.road === 0) ? 'serenade' : 'white'"
            v-model.trim.number="item.road"
            outlined
            hide-details
            dense
            :placeholder="'road'|localize"
            :rules="[validationRules.integer]"
            @blur="!item.road && (item.road = 0)"
          />
        </template>
      </v-data-table>
    </v-form>
    <v-container class="py-0">
      <v-row class="my-0" justify="end">
        <v-col cols="auto">
          <v-btn color="primary" depressed rounded :loading="saveLoading" :disabled="saveLoading || loading" @click="saveMargins">
            {{ 'save'|localize }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import localize from '@/filters/localize';
  import api from '@/api/api';
  import validationRules from '@/helpers/validationRules';
  import methods from '@/helpers/methods';

  export default {
    name: "FirstLevel",
    props: ['loading'],

    data() {
      return {
        validationRules,
        margins: [],
        saveLoading: false
      };
    },

    mounted() {
      this.getMargins();
    },

    computed: {
      headers() {
        return [
          {text: localize('air') + ', %', value: 'air', width: '25%', sortable: false},
          {text: localize('sea') + ', %', value: 'sea', width: '25%', sortable: false},
          {text: localize('rail') + ', %', value: 'rail', width: '25%', sortable: false},
          {text: localize('road') + ', %', value: 'road', width: '25%', sortable: false},
        ];
      }
    },

    methods: {
      setMarginsData(requestsData) {
        this.margins = [];
        this.margins.push(requestsData.data);
      },
      async getMargins() {
        try {
          this.$emit('updateLoading', true);
          let marginsData = await api.getFirstLevelMargins();
          this.setMarginsData(marginsData);
          this.$emit('updateLoading', false);
        } catch (e) {
          this.$emit('updateLoading', false);
        }
      },
      async saveMargins() {
        if (this.$refs.marginForm.validate()) {
          try {
            this.saveLoading = true;
            await api.saveFirstLevelMargins(this.margins[0]);
            let marginsData = await api.getFirstLevelMargins();
            this.setMarginsData(marginsData);
            methods.showGlobalSnackbar('success', localize('done'));
            this.saveLoading = false;
          } catch (e) {
            this.saveLoading = false;
            methods.showGlobalSnackbar('error', methods.getErrors(e.response));
          }
        }
      }
    },
  };
</script>
