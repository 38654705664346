var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{ref:"marginForm",attrs:{"lazy-validation":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.margins,"loading":_vm.loading,"item-key":"category","loader-height":"2","items-per-page":-1,"hide-default-footer":"","no-data-text":_vm._f("localize")('noDataAvailable')},scopedSlots:_vm._u([{key:"item.air",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"background-color":item.air || (item.air === 0) ? 'serenade' : 'white',"outlined":"","hide-details":"","dense":"","placeholder":_vm._f("localize")('air'),"rules":[_vm.validationRules.integer]},on:{"blur":function($event){!item.air && (item.air = 0)}},model:{value:(item.air),callback:function ($$v) {_vm.$set(item, "air", _vm._n((typeof $$v === 'string'? $$v.trim(): $$v)))},expression:"item.air"}})]}},{key:"item.sea",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"background-color":item.sea || (item.sea === 0) ? 'serenade' : 'white',"outlined":"","hide-details":"","dense":"","placeholder":_vm._f("localize")('sea'),"rules":[_vm.validationRules.integer]},on:{"blur":function($event){!item.sea && (item.sea = 0)}},model:{value:(item.sea),callback:function ($$v) {_vm.$set(item, "sea", _vm._n((typeof $$v === 'string'? $$v.trim(): $$v)))},expression:"item.sea"}})]}},{key:"item.rail",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"background-color":item.rail || (item.rail === 0) ? 'serenade' : 'white',"outlined":"","hide-details":"","dense":"","placeholder":_vm._f("localize")('rail'),"rules":[_vm.validationRules.integer]},on:{"blur":function($event){!item.rail && (item.rail = 0)}},model:{value:(item.rail),callback:function ($$v) {_vm.$set(item, "rail", _vm._n((typeof $$v === 'string'? $$v.trim(): $$v)))},expression:"item.rail"}})]}},{key:"item.road",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"background-color":item.road || (item.road === 0) ? 'serenade' : 'white',"outlined":"","hide-details":"","dense":"","placeholder":_vm._f("localize")('road'),"rules":[_vm.validationRules.integer]},on:{"blur":function($event){!item.road && (item.road = 0)}},model:{value:(item.road),callback:function ($$v) {_vm.$set(item, "road", _vm._n((typeof $$v === 'string'? $$v.trim(): $$v)))},expression:"item.road"}})]}}])})],1),_c('v-container',{staticClass:"py-0"},[_c('v-row',{staticClass:"my-0",attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary","depressed":"","rounded":"","loading":_vm.saveLoading,"disabled":_vm.saveLoading || _vm.loading},on:{"click":_vm.saveMargins}},[_vm._v(" "+_vm._s(_vm._f("localize")('save'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }