var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{ref:"marginForm",attrs:{"lazy-validation":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.margins,"loading":_vm.loading,"loader-height":"2","items-per-page":-1,"hide-default-footer":"","no-data-text":_vm._f("localize")('noDataAvailable')},scopedSlots:_vm._u([{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"background-color":"white","dense":"","hide-details":"","outlined":"","maxlength":"3","rules":[_vm.existingCategoryRule(), _vm.validationRules.required]},model:{value:(item.category),callback:function ($$v) {_vm.$set(item, "category", $$v)},expression:"item.category"}})]}},{key:"item.air",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"background-color":item.air || (item.air === 0) ? 'serenade' : 'white',"dense":"","hide-details":"","outlined":"","rules":[_vm.validationRules.integer]},on:{"blur":function($event){item.air = item.air !== '' ? item.air : null}},model:{value:(item.air),callback:function ($$v) {_vm.$set(item, "air", _vm._n((typeof $$v === 'string'? $$v.trim(): $$v)))},expression:"item.air"}})]}},{key:"item.sea",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"background-color":item.sea || (item.sea === 0) ? 'serenade' : 'white',"dense":"","hide-details":"","outlined":"","rules":[_vm.validationRules.integer]},on:{"blur":function($event){item.sea = item.sea !== '' ? item.sea : null}},model:{value:(item.sea),callback:function ($$v) {_vm.$set(item, "sea", _vm._n((typeof $$v === 'string'? $$v.trim(): $$v)))},expression:"item.sea"}})]}},{key:"item.rail",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"background-color":item.rail || (item.rail === 0) ? 'serenade' : 'white',"dense":"","hide-details":"","outlined":"","rules":[_vm.validationRules.integer]},on:{"blur":function($event){item.rail = item.rail !== '' ? item.rail : null}},model:{value:(item.rail),callback:function ($$v) {_vm.$set(item, "rail", _vm._n((typeof $$v === 'string'? $$v.trim(): $$v)))},expression:"item.rail"}})]}},{key:"item.road",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"background-color":item.road || (item.road === 0)? 'serenade' : 'white',"dense":"","hide-details":"","outlined":"","rules":[_vm.validationRules.integer]},on:{"blur":function($event){item.road = item.road !== '' ? item.road : null}},model:{value:(item.road),callback:function ($$v) {_vm.$set(item, "road", _vm._n((typeof $$v === 'string'? $$v.trim(): $$v)))},expression:"item.road"}})]}},{key:"item.settings",fn:function(ref){
var item = ref.item;
return [(item.category && item.category !== null)?_c('v-btn',{attrs:{"fab":"","depressed":"","x-small":"","color":"primary","disabled":_vm.addLoading || _vm.saveLoading || _vm.loading},on:{"click":function($event){return _vm.openRemoveCategory(item.category)}}},[_c('v-icon',{attrs:{"size":"25"}},[_vm._v("mdi-minus")])],1):_vm._e()]}}])})],1),_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"rounded":"","depressed":"","color":"primary","loading":_vm.addLoading,"disabled":_vm.addLoading || _vm.saveLoading || _vm.loading},on:{"click":_vm.addCategory}},[_c('v-icon',{staticClass:"mr-3",attrs:{"size":"25","left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm._f("localize")('addCategory'))+" ")],1)],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"6"}},[_c('v-btn',{attrs:{"rounded":"","depressed":"","color":"primary","loading":_vm.saveLoading,"disabled":_vm.addLoading || _vm.saveLoading || _vm.loading},on:{"click":_vm.saveMargin}},[_vm._v(" "+_vm._s(_vm._f("localize")('save'))+" ")])],1)],1)],1),_c('SimpleDialog',{ref:"deleteDialog",attrs:{"title":_vm._f("localize")('deleteCategoryTitle'),"text":_vm._f("localize")('deleteCategoryText'),"accept-text":_vm._f("localize")('delete'),"decline-text":_vm._f("localize")('back')},on:{"accept":_vm.removeCategory,"decline":_vm.cancelRemoveCategory}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }